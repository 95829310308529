import React, { useState, useContext, useRef } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { TableContext } from "../../contexts/tableContext";
import { getCookies } from "../../hooks/useCookies";
import envValues from "../../enviornment";
import styles from "./MultipleFileUpload.module.scss";
import audioIcon from "../../assets/equalizer.png";
import { useEffect } from "react";

function MultipleFileUpload({ editFiles, section, type, resetFile }) {
  console.log(section, "type");
  const fileRef = useRef(null);
  const [edited, setEdited] = useState(editFiles);
  const [uploadedImgs, setUplodedImgs] = useState([]);
  const { files, setFiles, setGetImage } = useContext(TableContext);
  const token = `Bearer ${getCookies("Token")}`;
  // eslint-disable-next-line no-unused-vars
  const imgUrl = [];
  const params = useParams();
  const { userId, topcultureId } = params;
  const pdfImg =
    "https://thesoftwarepro.com/wp-content/uploads/2019/12/microsoft-office-pdf-document-953x1024.jpg";
  const videoImg =
    "https://image.shutterstock.com/image-vector/play-button-icon-vector-illustration-260nw-1697833306.jpg";
  const handleChange = async (e) => {
    // console.log("File ref :: ", fileRef.current)
    console.log("eventt------", e);
    let { files: localFiles } = e.target;
    const validExtensions = ["mp4", "mov"];
    const audioExtensions = ["mp3"];
    const validImageExtensions = ["jpg", "jpeg", "png", "gif", "svg"];
    const maxDuration = 600; // 10 minutes in seconds
    const maxFileSize = 200 * 1024 * 1024; // 200 MB in bytes
    let fileTypes = new Set();
    const newUploadedImgs = [];
    localFiles = [...localFiles, ...files];

    for (let i = 0; i < localFiles.length; i++) {
      const file = localFiles[i];
      const extension = file.name.split(".").pop().toLowerCase();
      console.log(!validImageExtensions.includes(extension), "extension");
      if ((type === "article" || section === "topculture") && !validImageExtensions.includes(extension)) {
        toast.error(
          "Only image (jpg, jpeg, png, gif) and SVG files are allowed for Article."
        );
        return; // Skip this file and move to the next one
      }
      if (type === "article" && localFiles.length > 1) {
        toast.error("Only one image can be uploaded at a time");
        return;
      }
      if (section === "topculture" && localFiles.length > 1) {
        toast.error("Only one image can be uploaded at a time");
        return;
      }
      if (type === "media" && localFiles.length > 10) {
        toast.error("Cannot upload more than 10 images");
        return;
      }
      if (
        type === "media" &&
        !validImageExtensions.includes(extension) &&
        !audioExtensions.includes(extension) &&
        !validExtensions.includes(extension)
      ) {
        toast.error("Only images, video and audio files are allowed for Post.");
        return; // Stop processing further files
      }
      if (validImageExtensions.includes(extension)) {
        fileTypes.add("image");
      } else if (audioExtensions.includes(extension)) {
        fileTypes.add("audio");
      } else if (validExtensions.includes(extension)) {
        fileTypes.add("video");
      }

      // If more than one type of file is present, show error and return
      if (fileTypes.size > 1) {
        toast.error(
          "Only one type of file (image, audio, or video) can be uploaded at a time."
        );
        return;
      }

      if (validExtensions.includes(extension)) {
        const video = document.createElement("video");
        video.preload = "metadata";
        video.src = URL.createObjectURL(file);

        await new Promise((resolve) => {
          video.onloadedmetadata = () => {
            if (video.duration > maxDuration) {
              toast.error("Video duration exceeds 10 minutes");
            } else if (file.size > maxFileSize) {
              toast.error("Video size exceeds 200 MB");
            } else if (
              type === "article" &&
              !validImageExtensions.includes(extension)
            ) {
              toast.error(
                "Only image (jpg, jpeg, png, gif) and SVG files are allowed for articles."
              );
            } else {
              newUploadedImgs.push({
                imgurl: URL.createObjectURL(file),
                extension: "video",
                fileObject: file,
              });
            }
            resolve();
          };
        });
      } else if (audioExtensions.includes(extension)) {
        newUploadedImgs.push({
          imgurl: URL.createObjectURL(file),
          extension: "audio",
          fileObject: file,
        });
      } else {
        newUploadedImgs.push({
          imgurl: URL.createObjectURL(file),
          extension: "image",
          fileObject: file,
        });
      }
    }
    console.log("new uploaded images :: ", newUploadedImgs)
    setUplodedImgs(newUploadedImgs);
    console.log(files, "files");
    setFiles((prevFiles) => [...prevFiles, ...e.target.files]);
  };
  console.log("new uploaded images====>", uploadedImgs);

  const handleCancelButton = (param) => () => {
    const remainingFiles = uploadedImgs.filter(
      (value, index) => index !== param
    );
    if (!remainingFiles.length) {
      fileRef.current.value = null;
    }
    console.log(remainingFiles, "remaining");
    const updatedFiles = remainingFiles.map((item) => item.fileObject);
    console.log(updatedFiles, "updated");
    setUplodedImgs(remainingFiles);
    console.log(files, "setfiles");
    setFiles(updatedFiles);
  };

  const handleRemoveButton = (param) => () => {
    let url;
    if(section === "topculture"){
      url = `${envValues.REACT_APP_API_ENDPOINT}/${section}/${topcultureId}/${param}`
    }else{
      url = `${envValues.REACT_APP_API_ENDPOINT}/${section}/${userId}/${param}`
    }
    axios
      .delete(
        url,
        {
          headers: { Authorization: token },
        }
      )
      .then((res) => {
        setGetImage(res.data.data.result.files);
        console.log("response---", res);
        setEdited(res.data.data.result.files);
        // if (setFiles && typeof setFiles === 'function') {
        //   // Update files state by removing the deleted fileUrl
        //   setFiles((prevFiles) => prevFiles.filter((file) => file !== param));
        // }
        toast.success("File removed!");
      });
  };
  console.log(edited, "edited");
  console.log("Uploaded Images :: ", uploadedImgs);

  useEffect(() => {
    console.log("Set Files Called", files);
    if (!files?.length) {
      setUplodedImgs([]);
    }
  }, [files]);
  return (
    <div className="form-group">
      <div className="d-flex">
        <div className="d-flex">
          <div className="file-uploader-mask d-flex justify-content-center align-items-center">
            {/* <img
              className="file-uploader-icon"
              src="https://pic.onlinewebfonts.com/svg/img_150954.png"
              style={{ height: 20, width: 20 }}
              alt="Upload-Icon"
            /> */}
          </div>
          <input
            multiple
            className="file-input"
            id="upload"
            type="file"
            onChange={handleChange}
            hidden
            ref={fileRef}
          />
          <label htmlFor="upload" className={styles.upload_btn}>
            {console.log(type, "type")}
            {type === "article" ? "Add cover image" : "Choose File"}
          </label>
        </div>
      </div>
      <div className="d-flex flex-wrap mt-4">
        {edited != null
          ? edited?.map((uploadedImg, index) => (
              <div className={styles.close_icon_main}>
                <img
                  src={
                    section === "publishmedia" || "topculture" ? uploadedImg : uploadedImg.path
                  }
                  key={uploadedImg.path}
                  style={{ height: 100, width: 100, objectFit: "contain" }}
                  alt="UploadedImages"
                  onError={(e) => {
                    e.target.onerror = null;
                    const url = uploadedImg
                      .match(/\.([^.]+)$/)[1]
                      .toLowerCase();
                    const extension = url.split("-")[0].toLowerCase();
                    // Extracting the extension and converting it to lowercase
                    console.log(extension, "ddd");
                    // Now you can use this extension to determine the type of file
                    if (
                      extension === "mp3" ||
                      extension === "wav" ||
                      extension === "ogg"
                    ) {
                      e.target.src = audioIcon; // Set the source to audio image
                    } else if (
                      extension === "mp4" ||
                      extension === "avi" ||
                      extension === "mov"
                    ) {
                      e.target.src = videoImg; // Set the source to video image
                    } else if (extension === "pdf") {
                      e.target.src = pdfImg; // Set the source to PDF image
                    } else {
                      e.target.src = pdfImg; // Set a default image if the extension is not recognized
                    }
                  }}
                  className="img-thumbnail img-fluid uploaded-img mr-5 mb-5"
                />
                <button
                  type="button"
                  className={styles.close_icon}
                  onClick={handleRemoveButton(uploadedImg)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 19 19"
                    stroke="#60677e"
                    strokeWidth="2"
                    className="react-date-picker__clear-button__icon react-date-picker__button__icon"
                    style={{ marginTop: "-10px", marginLeft: "-3px" }}
                  >
                    <line x1="4" x2="15" y1="4" y2="15" />
                    <line x1="15" x2="4" y1="4" y2="15" />
                  </svg>
                </button>
              </div>
            ))
          : null}
      </div>
      <div className="image upload ff">
        {uploadedImgs != null &&
          uploadedImgs.map((uploadedImg, index) => (
            <div className={styles.uploaded_imgsection}>
              <img
                src={uploadedImg.imgurl}
                key={uploadedImg.imgurl}
                alt="UploadedImages"
                onError={(e) => {
                  e.target.onerror = null;
                  if (uploadedImg.extension === "video") {
                    e.target.src = videoImg;
                  } else if (uploadedImg.extension === "pdf") {
                    e.target.src = pdfImg;
                  } else if (uploadedImg.extension === "audio") {
                    e.target.src = audioIcon;
                  } else {
                    e.target.src = pdfImg;
                  }
                }}
                className="img-thumbnail img-fluid uploaded-img mr-2"
                id={styles.uploaded_img}
              />
              <button
                type="button"
                onClick={handleCancelButton(index)}
                className={styles.imgcancel_btn}
              >
                Cancel
              </button>
            </div>
          ))}
      </div>
    </div>
  );
}
MultipleFileUpload.propTypes = {
  editFiles: PropTypes.element,
  section: PropTypes.element,
  type: PropTypes.element,
};
MultipleFileUpload.defaultProps = {
  editFiles: null,
  section: "user",
};

export default MultipleFileUpload;
